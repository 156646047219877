import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2024/10E/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2024/10E/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2024/10E/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2024/10E/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2024/10E/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2024/10E/6.jpg';
// import p7 from 'assests/Photos/ClassAssembly/2024/10E/7.jpg';


// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly10E2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/10E/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/10E/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/10E/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/10E/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/class-assembly/10E/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/class-assembly/10E/6.webp`;
  const p7 = `${config.base_image_url}/home/events-activities/class-assembly/10E/7.webp`;
  
 
 




  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source: p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1.8,
        cols: 2,
      },

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      EVOLUTION OF ADVERTISMENTS
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 10E Date: 22 June 2024

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of class 10 E presented their class assembly on the topic EVOLUTION OF ADVERTISMENTS. On 20 June 2024, students introduced their assembly topic along with an interactive quiz on famous taglines and advertisements of the current age. On 22 June 2024, the class presented an informative and an entertaining skit displaying the evolution of advertisements from posters and print ads, from the past to the new short form of digital advertisements. The skit included scenes which displayed mascots of famous brands and the last scene was dedicated to the IPL advertisements that were enjoyed by the audience. The assembly left a thought provoking message for the audience. The students learnt many new skills like public speaking, dancing and enacting which will accompany them for a lifetime.
        <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “The more informative your advertising, the more persuasive it will be”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly10E2024;